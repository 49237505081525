<!-- 实名认证 -->
<template>
    <div class="realName ">

        <el-steps :active="active" finish-status="success">
            <el-step title="提交实名信息">

            </el-step>
            <el-step title="扫码验证"></el-step>
            <el-step title="验证结果"></el-step>
        </el-steps>
        <el-card class="box-card" style="width: 100%;padding: 20px 0 10px 0; margin-top: 30px;" v-loading="loading">

            <el-form :model="form" :rules="rules" ref="form" label-width="100px" style="width: 70%;" v-if="active == 0">
                <el-form-item label="证件类型">
                    <el-select v-model="idCardType" disabled placeholder="请选择">
                        <el-option label="身份证" :value="1">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="姓名" prop="u_real_name">
                    <el-input v-model="form.u_real_name">

                    </el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="u_id_card">
                    <el-input v-model="form.u_id_card">

                    </el-input>
                </el-form-item>

                <el-form-item label="">
                    <el-button type="primary" @click="submit"> 提交</el-button>
                </el-form-item>
            </el-form>
            <div class="flexCenter" v-if="active == 1">
                <div style="font-size: 20px;">支付宝扫码</div>
                <div id="qrcode" ref="qrcode" style="margin: 10px 0"></div>
                <div style="color: red; margin-bottom: 10px;">请使用支付宝扫码完成实名验证</div>
                <el-button type="primary" @click="getFaceCertifyResult">验证完成</el-button>
            </div>
            <div class="flexCenter" v-if="active == 2">
                <i class="el-icon-circle-check" style="font-size: 120px; color: #67c23a;"></i>
                <div>验证成功</div>

                <el-button type="success" style="margin-top: 20px;" @click="toUserCenter"> 完成</el-button>
            </div>

        </el-card>
    </div>
</template>

<script>
import IdentityCodeValid from './checkIdCard/index'
import QRCode from "qrcodejs2";
export default {
    data() {
        return {
            active: 0,
            idCardType: 1,
            loading: false,
            form: {
                u_id_card: '',
                u_real_name: '',
            },
            rules: {
                u_id_card: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' },
                ],
                u_real_name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ]
            },
        };
    },

    components: {

    },

    mounted() {

    },

    methods: {
        async submit() {

            await this.$refs['form'].validate()

            if (IdentityCodeValid(this.form.u_id_card) == false) {
                this.$message.error('身份证号不合法');
                return false;
            }
            this.loading = true
            let res = await this.$axios.post('/api/user/getFaceCertifyUrl', this.form)
            this.loading = false
            if (res.code === '000') {
                this.active = 1
                this.$nextTick(() => {
                    this.generateQRCode(res.data.certify_url)
                })
            } else {
                this.$message.error(res.msg)
            }

        },
        async getFaceCertifyResult() {
            this.loading = true
            let res = await this.$axios.post('/api/user/faceCertifyQuery')
            this.loading = false
            if (res.code == '000' || res.code == '163') {

                this.active = 2
            } else {
                this.$message.error('请先完成认证')

            }
        },
        toUserCenter() {
            this.$router.push({
                path: '/memberCenter'
            })
        },
        generateQRCode(url) {

            new QRCode('qrcode', {
                width: 350,
                height: 350,
                text: url
            });
        }
    }
}

</script>
<style scoped>
.realName {
    width: 50%;
}

.flexCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>